import React from 'react';
import MustLogin from './MustLogin';
import { useLogin } from '../contexts/loginContext'
const withLoginedRouteProtection = WrappedComponent => props => {
  const { isLoggedIn } = useLogin();
  return (
    isLoggedIn ? <WrappedComponent {...props} /> : <MustLogin />
  );
};

export default withLoginedRouteProtection;