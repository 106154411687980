export const DEFAULT_PAGE_SIZE = 30

export const DEFAULT_PAGE_NUM = 1

export const CACHE_KEY = {
    'user_info': 'user_info',
}

export const UserType = {
    'default': 0,
    'starter': 1,
    'pro': 2,
    'max': 3,
}

export const ACTION_MAP = {
    'P': '充值积分',
    'V': '购买会员'
}


export const ASSETS_PATH = 'https://ths-h5-1300040098.cos.ap-guangzhou.myqcloud.com/dalle/assets'

export const POINT_COST = {
    'draw': 1,
    'faceSwap': 2,
    'remove': 5,
    'restore': 1
}