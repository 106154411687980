import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { refreshUserInfo } from '../utils/user';

const RouteChangeListener = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    refreshUserInfo()

    const unlisten = history.listen((location, action) => {
      refreshUserInfo()
    });

    return () => {
      unlisten();
    };
  }, [history]);
  return null;
};

export default RouteChangeListener;