import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss'

function NotFound() {
  const history = useHistory()
  const { t } = useTranslation()

  const goHome = () => {
    history.replace('/home')
  }
  return (
    <div className={styles.not_found} style={{ height: `${window.innerHeight - 70}px` }}>
      <p>{t('page_not_found')}</p>
      <div className={styles.button} onClick={goHome}>
        {t('back_home')}
      </div>
    </div>
  );
}

export default NotFound;
