
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { setUserToStorage } from '../utils/user';
import { getInitialLang } from '../utils/common';
const LoginContext = createContext();

const initialLang = getInitialLang()

export const LoginProvider = ({ children }) => {
    const { i18n } = useTranslation();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loginModalVisible, setLoginModalVisible] = useState(false);
    const [showHeader, setShowHeader] = useState(true)
    const [lang, setLang] = useState(initialLang);
    const [rechargeModalVisible, setRechargeModalVisible] = useState(false);

    const closeRechargeModal = () => {
        setRechargeModalVisible(false);
    };

    const openRechargeModal = () => {
        setRechargeModalVisible(true);
    };

    const openLoginModal = () => {
        setLoginModalVisible(true);
    };

    const closeLoginModal = () => {
        setLoginModalVisible(false);
    };

    const login = () => setIsLoggedIn(true);
    const logout = () => {
        setIsLoggedIn(false);
        setUserToStorage({})
    }

    const toggleLang = (language) => {
        i18n.changeLanguage(language);
        localStorage.setItem('lang', language);
        setLang(language);
    };

    useEffect(() => {
        i18n.changeLanguage(initialLang);
    }, []);

    return (
        <LoginContext.Provider value={{ loginModalVisible, openLoginModal, closeLoginModal, isLoggedIn, login, logout, lang, toggleLang, showHeader, setShowHeader, rechargeModalVisible, openRechargeModal, closeRechargeModal }}>
            {children}
        </LoginContext.Provider>
    );
};

export const useLogin = () => {
    return useContext(LoginContext);
};
