import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLogin } from '../../contexts/loginContext'
import styles from './index.module.scss'

function MustLogin() {
  const { t } = useTranslation()

  const { openLoginModal } = useLogin();

  return (
    <div className={styles.must_login} style={{ height: `${window.innerHeight - 70}px` }}>
      <p>{t('login_then_visit')}</p>
      <div className={styles.button} onClick={openLoginModal}>
        {t('login')}
      </div>
    </div>
  );
}

export default MustLogin;
