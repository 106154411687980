import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Drawer } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import IconUp from '../../assets/svg/up.svg'
import LanguageSwitcher from '../Header/LanguageSwitcher'
import styles from './index.module.scss'
const NavDrawer = ({ navDrawerVisible, closeNavDrawer, routeList }) => {
  const location = useLocation();
  const [activeKey, setActiveKey] = useState(location.pathname);
  const history = useHistory();
  const { t } = useTranslation();

  const handleMenuClick = (path) => {
    history.push(path);
    setActiveKey(path)
    closeNavDrawer();
  };

  return (
    <Drawer
      title={null}
      placement="top"
      closable={false}
      onClose={closeNavDrawer}
      open={navDrawerVisible}
      className={styles.headerDrawer}
      style={{ padding: 0 }}
    >
      <div className={styles.top}>
        <div className={styles.home} onClick={() => handleMenuClick(routeList[0].path)}>
          <img loading="lazy" className={styles.icon} src={routeList[0].icon} alt="" />
          {/* <span className={`${styles.title}`} onClick={() => handleMenuClick(routeList[0].path)}>
            {routeList[0].text}
          </span> */}
        </div>
        <LanguageSwitcher />
        <img loading="lazy" onClick={closeNavDrawer} className={styles.icon_up} src={IconUp} alt="" />
      </div>

      <div className={styles.routes}>
        {routeList.slice(1).map((route, index) => (
          <div key={index} onClick={() => handleMenuClick(route.path)} className={styles.route_item}>
            <img loading="lazy" className={styles.icon} src={route.icon} alt="" />
            <span className={`${styles.title}`} key={route.path}>
              {route.text}
            </span>
          </div>
        ))}
      </div>
    </Drawer>
  );
};

export default NavDrawer;
