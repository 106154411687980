export const getWidth = () => {
    const minWidth = 320;
    const maxWidth = 1080;
    let width = document.documentElement.getBoundingClientRect().width;
    if (width < minWidth) {
        width = minWidth;
    }
    if (width > maxWidth) {
        width = maxWidth;
    }
    return width;
}

export const isMobile = () => getWidth() < 901
export function initRem(designWidth) {
    const width = getWidth();
    const rem = width * 10 / designWidth;
    document.documentElement.style.fontSize = rem + 'px';
}


export function getImgEditorBoxSize() {
    const screenWidth = window.innerWidth * 0.9;
    let width, height, left;

    if (window.innerWidth > 980) {
        console.log('1----', screenWidth)
        width = 0.96 * 440 ;
        left = 0.02 * 440
    } else {
        console.log('2----', screenWidth)
        width = 0.96 * screenWidth;
        left = 0.02 * screenWidth
        if (width > 540) {
            width = 540
            left = screenWidth / 2 - 270
        }
    }
    height = 360;
    return { width, height, left };
}
