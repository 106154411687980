export const CONFIG = {
    default_parent: 'P000000',
    server_domain: "https://www.ths-server.shop",
    app_id: 'dalle', // 数据库名
    exchange_rate: 7.26, // 汇率
    domain: 'c8c.icu',
    email: 'openai.online.cc@gmail.com', // 联系邮箱
    list: [
        {
            domain: 'localhost', // 域名，标识
            app_name: 'localhost', // Header 左上角显示
            app_id: '00',
            lang: 'both', // 支持语言
            vip_price: {
                starter: 2.8,
                pro: 9.8,
                max: 19.8,
            }
        },
        {
            domain: 'openai-online.cc', // 域名，标识
            app_name: 'OpenAI Online', // Header 左上角显示
            app_id: '01',
            lang: 'both', // 支持语言 zh, en, both
            vip_price: {
                starter: 2.8,
                pro: 9.8,
                max: 19.8,
            }
        },
        {
            domain: 'xn--sqrz0ep07c.com', // 域名，标识
            app_name: '绘图君.com', // Header 左上角显示
            app_id: '02',
            lang: 'both', // 支持语言
            vip_price: {
                starter: 2.8,
                pro: 9.8,
                max: 19.8,
            }
        },
        {
            domain: 'xn--sqrz0ep07c.xyz', // 域名，标识
            app_name: '绘图君.xyz', // Header 左上角显示
            app_id: '03',
            lang: 'both', // 支持语言
            vip_price: {
                starter: 2.8,
                pro: 9.8,
                max: 19.8,
            }
        }
    ]
}
