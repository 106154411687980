import { CONFIG } from '../config'
export function throttle(func, delay) {
    let lastCalled = 0;
    return function (...args) {
        const now = new Date().getTime();
        if (now - lastCalled >= delay) {
            func(...args);
            lastCalled = now;
        }
    };
}


export function getWebConfig(key) {
    const hostname = window.location.hostname;
    let webConfig = CONFIG.list.find(item => item.domain === hostname)
    if(!webConfig) {
        webConfig = {
            domain: 'localhost', // 域名，标识
            app_name: 'localhost', // Header 左上角显示
            app_id: '00',
            lang: 'both', // 支持语言
            vip_price: {
                starter: 2.8,
                pro: 9.8,
                max: 19.8,
            }
        }
    }
    return webConfig[key]
}

export function getInitialLang() {
    let cacheLang = localStorage.getItem('lang')
    if(cacheLang) {
        return cacheLang
    } else {
        return 'zh'
        // let langSupported = getWebConfig('lang')
        // return langSupported === 'both' ? 'zh' : langSupported
    }
}


export function formatAmount(amount) {
    return Number(amount).toFixed(2)
}

export function getRandomInt(min, max) {
    // 生成随机数
    return Math.floor(Math.random() * (max - min + 1)) + min;
}