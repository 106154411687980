import React from 'react';
import styles from './index.module.scss';

const ListLoading = () => {
  return (
    <div className={styles["list-loading"]}>
      {[...Array(12).keys()].map(index => (
        <div key={index} className={styles[`bar${index + 1}`]}></div>
      ))}
    </div>
  );
};

export default ListLoading;
