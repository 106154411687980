import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useLogin } from '../../contexts/loginContext'
import LanguageSwitcher from './LanguageSwitcher'
import NavDrawer from '../NavDrawer'
// import TopBar from '../TopBar'
import LogoPng from '../../assets/images/logo.png'
import MenuSvg from '../../assets/svg/menu.svg'
import HomeSvg from '../../assets/svg/home.svg'
import CreateSvg from '../../assets/svg/create.svg'
import AccountSvg from '../../assets/svg/account.svg'
import HistorySvg from '../../assets/svg/history.svg'
import PointSvg from '../../assets/svg/point.svg'

import ArrowRightTopSvg from '../../assets/svg/arrow_right_top.svg'
import { getUserFromStorage } from '../../utils/user';
import styles from './index.module.scss';

const { TabPane } = Tabs;

const Header = (props) => {
  const { isMobile } = props
  const userInfo = getUserFromStorage()
  const { openLoginModal, isLoggedIn, login, showHeader } = useLogin();
  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const [activeKey, setActiveKey] = useState(location.pathname);
  const [bgColor, setBgColor] = useState('transparent');
  const [navDrawerVisible, setNavDrawerVisible] = useState(false);

  const openNavDrawer = () => {
    setNavDrawerVisible(true);
  };

  const closeNavDrawer = () => {
    setNavDrawerVisible(false);
  };

  useEffect(() => {
    setActiveKey(location.pathname)
  }, [location.pathname])

  const handleTabChange = key => {
    setActiveKey(key)
    history.push(key)
  }

  const backHome = () => {
    history.push('/');
    setActiveKey('/')
  }

  const ROUTE_LIST = [
    {
      text: t('nav_home'),
      path: '/',
      icon: HomeSvg
    },
    {
      text: t('nav_process'),
      path: '/create',
      icon: CreateSvg
    },
    // {
    //   text: t('nav_community'),
    //   path: '/',
    // },
    {
      text: t('nav_history'),
      path: '/history',
      icon: HistorySvg
    },
    {
      text: t('nav_points'),
      path: '/points',
      icon: PointSvg
    },
    {
      text: t('nav_account'),
      path: '/account',
      icon: AccountSvg
    },
  ]

  useEffect(() => {
    if (Object.keys(userInfo).length) {
      login()
    }
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      if (scrollPosition === 0) {
        setBgColor('transparent');
      } else if (scrollPosition > 30) {
        setBgColor('black');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {/* {showHeader ? <TopBar /> : null} */}
      {showHeader ? <div className={styles.header} style={{ backgroundColor: bgColor }}>
        <div className={styles.left} onClick={backHome}>
          <img loading="lazy" className={styles.icon} src={LogoPng} alt="" />
          {/* <span>{getWebConfig('app_name')}</span> */}
        </div>
        {
          isMobile ?
            <></> :
            <div className={styles.center}>
              <Tabs
                activeKey={activeKey}
                onChange={handleTabChange}
              >
                {
                  ROUTE_LIST.map((route, index) => (
                    <TabPane tab={route.text} key={route.path} />
                  ))
                }
              </Tabs>
            </div>
        }
        <LanguageSwitcher />
        {/* {getWebConfig('lang') === 'both' ? <LanguageSwitcher /> : null} */}
        <div className={styles.right}>
          {
            isMobile ?
              <img loading="lazy" onClick={openNavDrawer} className={styles.icon_menu} src={MenuSvg} alt='' />
              :
              isLoggedIn ?
                null
                :
                <>
                  <span onClick={openLoginModal} className={styles.login}>{t('nav_login')}</span>
                  <img loading="lazy" onClick={openLoginModal} className={styles.icon_arrow} src={ArrowRightTopSvg} alt="" />
                </>
          }
        </div>
      </div> : null}
      <NavDrawer navDrawerVisible={navDrawerVisible} closeNavDrawer={closeNavDrawer} routeList={ROUTE_LIST} />
    </>
  );
};

export default Header;
