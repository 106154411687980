import React, { useEffect, useState } from 'react';
import { Dropdown } from 'antd';
import { useLogin } from '../../../contexts/loginContext'
import WorldSvg from '../../../assets/svg/world.svg'
import styles from './index.module.scss'
const LanguageSwitcher = () => {
  const { lang, toggleLang } = useLogin();
  const [language, setLanguage] = useState(lang);

  const onClick = ({ key }) => {
    setLanguage(key)
    toggleLang(key)
  };

  const items = [
    {
      key: 'zh',
      label: '中文',
    },
    {
      key: 'en',
      label: 'English',
    },
  ]

  const LangMap = {
    'zh': '中文',
    'en': 'English'
  }

  useEffect(() => {
    setLanguage(lang)
  }, [lang])

  return (
    <Dropdown placement="bottom" menu={{
      items,
      onClick,
    }}>
      <div className={styles.lang_box}>
        <span className={styles.text}>{LangMap[language]}</span>
        {/* <DownOutlined /> */}
        <img loading="lazy" className={styles.icon} src={WorldSvg} alt="" />
      </div>
    </Dropdown>
  );
};

export default LanguageSwitcher;
