import React from "react";
import { useTranslation } from 'react-i18next';
import NoDataSvg from '../../assets/svg/no-data.svg'
import ListLoading from '../ListLoading'
import styles from "./index.module.scss";

function ListBox(props) {
  const { t } = useTranslation()
  if (props.loading) {
    return (
      <div className={styles.loading_box}>
        <ListLoading />
      </div>
    )
  } else if (props.length > 0) {
    return (
      <>
        {props.children}
      </>
    )
  }
  return (
    <div className={styles['no-data-wrap']}>
      <img loading="lazy" alt='' className={styles['no_data_icon']} src={NoDataSvg} />
      <div className={styles['text']}>{t('no_result')}</div>
    </div>
  )
}

export default ListBox;
