import React from 'react';
import ReactDOM from 'react-dom/client';
import '@babel/polyfill';
import { StyleProvider, legacyLogicalPropertiesTransformer } from '@ant-design/cssinjs';
import './index.css';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18n from './utils/i18n';

// hashPriority="high" 必须要，否则部分 antd 覆盖样式不生效
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <StyleProvider transformers={[legacyLogicalPropertiesTransformer]} hashPriority="high">
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </StyleProvider>

  // </React.StrictMode>
);

